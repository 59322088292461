<template>
  <div class="withdraw">
    <!-- 导航栏 -->
    <Navbar :title="$t('page.walletList')" />

    <van-cell v-for="(item, index) in wallets" :key="index">
      <!-- :style="{
        border: item.id == walletID ? '1px solid var(--theme,#ff0000)' : '',
      }" -->
      <!-- <div slot="title" class="title" @click=select(item)> -->
      <div slot="title" class="title">
        <van-checkbox-group :value="radio">
          <van-checkbox :name="index" @click="radioClick(index)">
          </van-checkbox>
        </van-checkbox-group>
        <div class="item-title" @click.top="select(item)">
          {{ item.bank }}
        </div>
        <span class="item-right" @click="select(item)">
          <!-- language-ch 尾号 -->
          <span class="item-value-bank">
            <!-- tail number -->
            <div v-if="item.bank !== 'USDT'">
              <span style="color: var(--gray2)">{{ $t("newAdd.Name") }}:</span>{{ "\n" + item.name ? item.name : "" }}
            </div>
            <div v-else>
              <span style="color: var(--gray2)">{{ $t("withdraw.currency.label") }}:</span>
              &nbsp;USDT
            </div>
            <div v-if="item.bank !== 'USDT'">
              <span style="color: var(--gray2)">{{ $t("newAdd.BankName") }}:</span>{{ "\n" + item.bank ? item.bank : ""
              }}
            </div>
            <div v-else>
              <span style="color: var(--gray2)">{{ $t("newAdd.ChainType") }}:</span>{{ "\n" + item.open ? item.open : ""
              }}
            </div>
            <div v-if="item.bank !== 'USDT'">
              <span style="color: var(--gray2)">{{ $t("newAdd.CardNum") }}:</span>{{ "\n" + item.card ? item.card : ""
              }}
            </div>
            <div v-else>
              <span style="color: var(--gray2)">{{ $t("newAdd.WalletAddress") }}:</span>{{ "\n" + item.card ? item.card
      :
      "" }}
            </div>
          </span>
        </span>
        <!-- <span @click.stop="toAddCard(item)" class="edit" v-if="verConfig.allowedEditBankInfo">
          {{ $t("btn.editWallet") }}
        </span> -->
      </div>
      <template #right-icon v-if="bankInfo.id">
        <van-icon name="arrow" slot="icon" />
      </template>
    </van-cell>

    <!-- language-ch 提 现 -->
    <!-- v-if="wallets.length < 1" -->
    <div class="btns">
      <button class="button" @click="toAddCard">
        {{ radio.length > 0 ? $t("btn.editWallet") : $t("addWallet") }}
      </button>
      <template v-if="radio.length > 0">
        <button class="btns_delete" @click="deleteClick">
          {{ $t('address.btn.delete') }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { GET_USER_BANK_INFO, WITHDRAW, getUserInfoNew, DELETE_BANK } from "@/api";
import { isObject, count, getUserInfo, getToken } from "@/utils";
// import { userBank, extract } from '@/api/use.js'
import { NavBar, cell, icon, field, Dialog, Toast, Tab, Tabs, Radio, RadioGroup, Checkbox, CheckboxGroup } from "vant";
import Cookies from "js-cookie";
import Tabbar from "@/components/Tabbar";
import verConfig from "../../utils/verConfig";
import Navbar from "@/components/JNav";
import { maskMiddle } from "@/utils/common"
export default {
  name: "",
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    Tabbar,
    Navbar,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data() {
    return {
      wallets: [],
      walletID: "",
      sms: "",
      country: Cookies.get("language"),
      money: "",
      bankInfo: {},
      userInfo: {},
      isshowDialog: false,
      editValue: "",
      money_not: 0,
      // isshowpassword: this.$route.query.isshowpassword
      //   ? this.$route.query.isshowpassword
      //   : false,
      paypassword: this.$route.query.paypassword
        ? this.$route.query.paypassword
        : true,
      withdrawType: verConfig.needBank ? "bank" : "coin",
      // actualAmount: 0,
      rateData: {},
      radio: [],
    };
  },
  created() {
    this.getUserBankInfo();
  },
  mounted() {
    this.getUserBankInfo();
    this.userInfo = getUserInfo();
    const { id } = this.$route.query;
    !!id && (this.walletID = id);
  },
  methods: {
    radioClick(index) {
      const flag = this.radio.includes(index);
      if (flag) {
        this.radio = [];
      } else {
        this.radio = [index];
      }
    },
    handleLinkPage(path) {
      if (getToken()) {
        this.$router.push(`/${path}`);
      } else {
        this.$toast($t("newAdd.NotLogin"));
        setTimeout(() => {
          this.$router.replace({ name: "login" });
        }, 1000);
      }
    },
    getUserBankInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_USER_BANK_INFO()
        .then((r) => {
          Toast.clear();
          const { data } = r.data;
          // if (r.data.ret === 1 && data.length > 0) {
          //   Toast.clear();
          //   console.log(data,'????');
          //   this.wallets = data;
          // }
          if (r.data.ret == 1) {
            Toast.clear();
            this.wallets = data.length && data.map((item)=>{
              item.card = maskMiddle(item.card)
              return item
            });
            if (data == null) {
              this.$cookie.remove('selectBankInfo')
            }
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
    select(item) {
      this.$cookie.set("selectBankInfo", JSON.stringify(item));
      this.$router.go(-1);
    },
    async deleteClick() {
      try {
        const index = this.radio[0];
        const item = this.wallets[index];
        const res = await DELETE_BANK(item.id);
        Toast(res.msg);
        this.radio = [];
        this.getUserBankInfo();
      } catch (error) {
        console.log(error);
        Toast(error.msg)
      }
    },
    toAddCard() {
      const index = this.radio[0];
      const item = this.wallets[index];
      this.handleLinkPage(`add-bank-card${!!item ? "?id=" + item.id + '&listLength=' + this.wallets.length : "?listLength=" + this.wallets.length}`);
    },
  },
};
</script>

<style lang="less" scoped>
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
@Color: var(--theme);
// @comBG: #07304a;
@comBG: var(--bg1);

.withdraw {
  width: 100%;
  height: 100vh;
  // background-color: #f4f8f9;
  background: var(--bg);

  .van-cell {
    // margin: calc(7.5rem / 16) 0;
    font-size: calc(12rem / 16);
    color: var(--theme);
    display: flex;
    align-items: center;
    background: @comBG;
    margin: @wrapPadding;
    border-radius: @comRadius;
    width: calc(100% - @comRadius * 2);

    &::after {
      border: none;
    }

    // i {
    //   // margin-top: calc(18rem / 16);
    // }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      // .item_right {

      //   :deep(.van-checkbox__label) {
      //     flex: 1;
      //   }

      .item-title {
        height: 100%;
        width: calc(70rem /16);
        white-space: wrap;
        margin: 0 calc(12rem / 16);
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // }

      .item-right {
        width: calc(250rem / 16);
        display: flex;
        flex-direction: row;
        // margin-left: calc(15rem / 16);
      }

      .edit {
        background: var(--theme, #ff0000);
        padding: 0 10px;
        border-radius: 6px;
        // color: var(--textColor);
        color: var(--light);
      }
    }

    .balance {
      width: calc(185rem / 16);

      .balance-title {
        width: calc(200rem / 16);
      }

      .balance-right {
        margin-left: calc(10rem / 16);
      }
    }

    .default {
      color: #000;

      .item-value {
        margin-left: calc(10rem / 16);
      }
    }

    .red {
      color: var(--theme, #ff0000);
    }
  }

  .item-need {
    color: #dc1323;
    text-align: right;
    font-size: calc(12rem / 16);
    margin-top: calc(12rem / 16);
    margin-right: calc(12rem / 16);
    margin-bottom: calc(12rem / 16);
  }

  .van-field {
    font-size: calc(15rem / 16);

    /deep/.van-field__label {
      width: calc(15rem / 16);
    }

    button {
      border: none;
      color: var(--theme, #ff0000);
      background-color: #fff;
    }
  }

  .btns {
    margin: @wrapPadding;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .button {
      // position: absolute;
      // left: 50%;
      // transform: translateX(-50%);
      width: calc(340rem / 16);
      height: calc(43rem / 16);
      font-size: calc(15rem / 16);
      margin-top: calc(31rem / 16);
      background-color: var(--theme, #ff0000);
      border-radius: calc(5rem / 16);
      border: none;
      // color: var(--textColor);
      color: var(--light);
    }

    &_delete {
      width: calc(340rem / 16);
      height: calc(43rem / 16);
      font-size: calc(15rem / 16);
      margin-top: calc(12rem / 16);
      border-radius: calc(5rem / 16);
      border: none;
      // color: var(--textColor);
      color: var(--light);
      background-color: #ff6a6a;
    }
  }

  .van-field {
    /deep/.van-field__body {
      input {
        text-align: center;
      }
    }
  }
}
</style>